<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品分类</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <!-- 上面部分 -->
        <el-col>
          <el-button type="primary" @click="addobj()">添加分类</el-button>
        </el-col>
      </el-row>

      <el-table :data="catelist" border style="width: 100%">
        <el-table-column prop="sort" label="排序"> </el-table-column>
        <el-table-column prop="name" label="分类名称"> </el-table-column>
        <el-table-column prop="categoryName" label="分类字段"> </el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" @click="addobj(scope.row)">编辑</el-button>
            <el-button type="danger" @click="_remove(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加商品分类对话框 -->
    <el-dialog
      title="提示"
      :visible.sync="addCateDialogVisible"
      width="50%"
      @close="addCateDialogClosed"
    >
      <!-- 主要内容 -->
      <el-form
        :model="cateForm"
        :rules="cateFormRules"
        ref="cateFormRef"
        label-width="100px"
      >
        <el-form-item label="分类名称" prop="categoryName">
          <el-input v-model="cateForm.categoryName"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCate">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页
      pages: {
        currentPage: 1,
        pageSize: 5,
      },

      // 总条数
      total: 0,

      //商品分类的数据类别 默认为空
      catelist: [],

      // 控制添加分类对话框的显示与隐藏
      addCateDialogVisible: false,

      // 添加分类的表单数据对象
      cateForm: {
        categoryName: '',
      },

      // 添加分类表单的验证规则对象
      cateFormRules: {
        categoryName: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
      },

    }
  },
  created() {
    // 商品分类请求
    this.getGoodsCate()
  },
  methods: {
    addobj (data) {
      let p = '';
      if(data){
        p = '?id=' + data.categoryId;
        var groupInfo = this.catelist.find(o => o.categoryId == data.categoryId);
        localStorage.setItem('cate-' + data.categoryId, JSON.stringify(groupInfo));
      }
      this.$router.push('/addCate' + p)
    },
    //请求商品分类
    getGoodsCate() {
      this.$api.GetGoodsCategoryList(this.pages).then((res) => {
        if (res.code !== 200) this.$message.error('请求商品分类失败')
        this.catelist = res.data
        // this.total = res.data.total
      })
    },

    // 点击添加分类
    showCateDialog(data) {
      if(data){
        this.cateForm = {...data}
      }else{
        this.cateForm = {categoryName: ''}
      }
      this.addCateDialogVisible = true
    },

    //  点击确定按钮 添加新的分类
    addCate() {
      // 表单预验证
      this.$refs.cateFormRef.validate((valid) => {
        if (!valid) return
        
        this.$api.UpdateAddDeleteGoodsCategory(this.cateForm).then((res) => {
          if (res.code !== 200) return this.$message.error('操作失败')
          
          this.$message.success('操作成功')
          this.addCateDialogVisible = false
          this.getGoodsCate()
        })
      })
    },

    // 监听对话框的关闭事件，重置表单数据
    addCateDialogClosed() {
      this.$refs.cateFormRef.resetFields()
    },
    // 删除
    async _remove (id) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') return
        
      this.$api.UpdateAddDeleteGoodsCategory({id}).then((res) => {
        if (res.code !== 200) {
          this.$message.error('删除失败')
        } else {
          this.$message.success('删除成功')
        }
        // 刷新列表
        this.getGoodsCate()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.treeTable {
  margin: 20px 0 20px 0;
}

// 下拉框展示
.el-cascader {
  width: 100%;
}
</style>
